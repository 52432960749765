import { firestoreDB } from '@app/auth/firebase';
import { AuthAxios } from '@app/services/interceptor.service';
import { ORDER_STATUS } from '@app/utilities/constants';
import axios from 'axios';
import { doc, getDoc, updateDoc, arrayUnion, setDoc, addDoc, limit, collection, query } from 'firebase/firestore';

const NEXT_PUBLIC_API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const fetchUserInfo = async (uid: string) => {
  try {
    // const userRef = await firestore.collection('Users').doc(uid).get();
    // return userRef.data();
    // const docRef = await ;
    const userRef = await getDoc(doc(firestoreDB, 'Users', uid));
    return userRef.data();
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const updateUserInfo = async (uid: string, userInfo: object) => {
  try {
    console.log('userInfpo', userInfo);
    // const docRef = await ;
    await setDoc(doc(firestoreDB, 'Users', uid), userInfo, { merge: true });
    // await firestore.collection('Users').doc(uid).set(userInfo, { merge: true });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const updateProgramInfo = async (uid: string, primary: string, secondary: string, programIds: Array<{}>) => {
  try {
    const body = {
      fields: {
        concerns: {
          mapValue: {
            fields: {
              primary: {
                stringValue: primary,
              },
              secondary: {
                stringValue: secondary,
              },
            },
          },
        },
        programIds: {
          arrayValue: {
            values: programIds,
          },
        },
      },
    };

    const res = await fetch(
      `https://firestore.googleapis.com/v1/projects/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/databases/(default)/documents/Users/${uid}?currentDocument.exists=true&updateMask.fieldPaths=concerns&updateMask.fieldPaths=programIds&key=[YOUR_API_KEY]`,
      {
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${window.FirebaseToken}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateAccessCode = async (uid: string, accessCode: string) => {
  try {
    const body = {
      fields: {
        accessCode: {
          stringValue: accessCode,
        },
      },
    };

    const res = await fetch(
      `https://firestore.googleapis.com/v1/projects/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/databases/(default)/documents/Users/${uid}?currentDocument.exists=true&updateMask.fieldPaths=accessCode`,
      {
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${window.FirebaseToken}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const addUserAddress = async (uid: string, newAddress: object) => {
  try {
    const data = {
      my_address: arrayUnion(newAddress),
    };
    const docRef = await doc(firestoreDB, 'Users', uid);
    await updateDoc(docRef, data);

    // await firestore
    //   .collection('Users')
    //   .doc(uid)
    //   .update({
    //     my_address: firebase.firestore.FieldValue.arrayUnion(newAddress),
    //   });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};
export const updateUserAddress = async (uid: string, newAddress: object) => {
  try {
    const data = { my_address: newAddress };
    const docRef = await doc(firestoreDB, 'Users', uid);
    await setDoc(docRef, data, { merge: true });

    // await firestore.collection('Users').doc(uid).set({ my_address: newAddress }, { merge: true });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const deleteUserAddress = async (uid: string, newAddress: object) => {
  try {
    const data = { my_address: newAddress };
    const docRef = await doc(firestoreDB, 'Users', uid);
    await setDoc(docRef, data, { merge: true });

    // await firestore.collection('Users').doc(uid).set({ my_address: newAddress }, { merge: true });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

type TgetCoraCash = {
  email: string;
  phoneNumber: string;
};

export const getCoraCash = async (params: TgetCoraCash) => {
  try {
    if (!process.env.NEXT_PUBLIC_CORA_CASH_API) return;
    const emailAddr = params?.email ? params?.email : '';

    const res = await AuthAxios.get(
      `${process.env.NEXT_PUBLIC_CORA_CASH_API}/cora-cash-balance?phoneNumber=${params?.phoneNumber}&email=` + emailAddr
    );

    const data = res?.data?.result;

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
    }
  }
};

export const getAvailableCupons = async () => {
  try {
    const docRef = await doc(firestoreDB, 'coupons', 'everyone');
    const couponRef = await getDoc(docRef);

    // const couponRef = await firestore.collection('coupons').doc('everyone').get();
    return couponRef.data();
  } catch (error) {
    if (error instanceof Error) {
      console.error('couponRef', error);
    }
  }
};

export const checkPincode = async (params: any) => {
  try {
    const payload: any = {
      params: {
        pid: params?.productId,
        pname: params?.handle,
        pc: params?.pincode,
      },
    };
    const response = await AuthAxios.get(`${NEXT_PUBLIC_API_BASE_URL}/api/pincode-check`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export interface ICustomerDetails {
  customer_gid?: string;
  customerId?: string;
  email?: string;
  numberOfOrders?: string;
  totalAmountSpent?: string;
  firstOrderDate?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export const addEmail = async (email: string) => {
  try {
    const data = {
      email: email,
    };
    const colRef = await collection(firestoreDB, 'Emails');
    await addDoc(colRef, data);

    // await firestore.collection('Emails').add({
    //   email: email,
    // });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const myndSubscribeEmail = async (email: string) => {
  try {
    const data = {
      email: email,
    };
    const colRef = await collection(firestoreDB, 'MyndRequestAccess');
    await addDoc(colRef, data);

    // await firestore.collection('Emails').add({
    //   email: email,
    // });
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export async function getShopifyCustomerByPhone(phone: string) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('get-customer-by-phone', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: {
        phone,
      },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
  }
}

export async function getShopifyCustomerByID(customer_id: string) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('shopify-customer', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: {
        customer_id,
      },
    });
    if (response.status == 200) {
      return await response.data;
    }

    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
  }
}

export async function getShopifyCustomerByEmail(email: string) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('get-customer-by-email', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: {
        email,
      },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
  }
}

export async function createShopifyCustomer(phone: string, email?: string) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('create-shopify-customer', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: {
        phone,
        email,
      },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
  }
}

type UpdateInput = {
  phone?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export async function updateShopifyCustomer(customer_id: string, input: UpdateInput) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('update-shopify-customer', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: {
        id: customer_id,
        ...input,
      },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
  }
}

export type CustomerOrdersQuery = {
  customer_id: string;
  limit: number;
  status: 'any' | 'cancelled';
  query?: string;
  last_id?: number;
  direction?: 'next' | 'prev';
};
export async function getShopifyCustomerOrders(query: CustomerOrdersQuery) {
  try {
    const response = await axios({
      method: 'post',
      url: new URL('customer-orders', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      data: query,
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
    return {};
  }
}

export async function getShopifyCustomerDraftOrders(customer_id: string) {
  try {
    const response = await axios({
      method: 'get',
      url: new URL('customer-draft-orders', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      params: { customer_id },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
    return {};
  }
}

export async function getShopifyOrderById(order_id: string) {
  try {
    const response = await axios({
      method: 'get',
      url: new URL('order-by-id', process.env.NEXT_PUBLIC_API_BASE_URL).toString(),
      params: { order_id },
    });
    if (response.status == 200) {
      return await response.data;
    }
    throw { status_code: response.status, message: 'network request error' };
  } catch (error) {
    console.log('error', error);
    return {};
  }
}
